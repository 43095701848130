<template>
    <div class="page-gray" style="padding-top: 1px;">
        <div class="filter">
            <div class="top">
<!--                <div class="handle month" @click="monthPopup = true">-->
<!--                    <div class="value" v-if="month"> {{month}}</div>-->
<!--                    <div class="placehoder" v-else>选择月份</div>-->
<!--                    <img src="./../../assets/images/integral/arrow.png" alt="">-->
<!--                </div>-->
                <div class="handle" @click="startTimePopup = true">
                    <div class="value" v-if="startTime"> {{startTime}}</div>
                    <div class="placehoder" v-else>选择开始时间</div>
                    <img src="./../../assets/images/integral/arrow.png" alt="">
                    <i />
                    <div class="value" v-if="endTime"> {{endTime}}</div>
                    <div class="placehoder" v-else>选择结束时间</div>
                    <img src="./../../assets/images/integral/arrow.png" alt="">
                </div>


                
            </div>


             <div class="bottom">
                <div class="right">
                    <span style="background: #22AC38;"></span>
                    <div class="key">订单数：</div>
                    <div class="value">100000</div>
                </div>

                <div class="right" style="margin-left: 20px">
                    <span style="background: #00B7EE;"></span>
                    <div class="key">分润：</div>
                    <div class="value">480000</div>
                    <i>元</i>
                </div>

               
            </div>
        </div>

        
        <div class="list">

            <div class="card">
                <div class="top van-hairline--bottom">
                    <div class="orderNum">
                        <div>订单号：E123456789108097</div>
                        <div class="state">已支付</div>
                    </div>
                    <div class="from">订单来源：湘潭子敬学校-四年级-1802班-王玉婷</div>
                </div>

                <div class="content">
                    <img src="./../../assets/images/order/submitOrder/dxyxz_1@2x.png" alt="">

                    <div class="right">
                        <div class="name van-multi-ellipsis--l2">kk剑桥树儿童雨衣男童宝宝雨披女批带书包位小学生雨衣kk剑桥树儿童雨衣男童宝宝雨披女批带书包位小学生雨衣kk剑桥树儿童雨衣男童宝宝雨披女批带书包位小学生雨衣 时尚新款</div>

                        <div class="price">
                            <div class="money">
                                <span>¥</span>
                                <span>88</span>
                            </div>

                            <div class="count">x1</div>
                        </div>

                        <div class="shifu">
                            <span>实付：</span>
                            <span class="money">¥74.8</span>
                            <span class="integral">+132积分</span>
                        </div>
                    </div>

                </div>
                <div class="orderInfo">
                    <div class="item">
                        <div class="key">厂家分润金额：</div>
                        <div class="value">0.28元</div>
                    </div>
                    <div class="item">
                        <div class="key">下单时间：</div>
                        <div class="value">2022-05-24  16:42:12</div>
                    </div>
                </div>
            </div>
        </div>

        <van-popup v-model="monthPopup" round position="bottom">
            <van-picker
                title="选择月份"
                show-toolbar
                :columns="monthList"
                @confirm="onMonthConfirm"
            />
        </van-popup>

        <van-popup v-model="startTimePopup" round position="bottom">
            <van-datetime-picker
                type="date"
                title="选择开始时间"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="onStartConfirm"
            />
        </van-popup>

        <van-popup v-model="endTimePopup" round position="bottom">
            <van-datetime-picker
                type="date"
                title="选择结束时间"
                :min-date="startTimeStack"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="onEndConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "IntegralOrderList",
    data() {
        return {
            startTimePopup: false,
            endTimePopup: false,
            monthPopup: false,
            maxDate: new Date(),
            startTimeStack: '',

            monthList: [],
            month: '',
            startTime: '',
            endTime: '',
            
        }
    },

    methods: {
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }else if (type === 'day') {
                return `${val}日`;
            }
            return val;
        },

        onMonthConfirm(val) {
            // val => {text: 1月, value: 1}
            this.month = val.text;
            this.monthPopup = false
        },

        onStartConfirm(val) {
            this.startTimeStack = val;
            this.startTimePopup = false;
            this.endTimePopup = true
        },
        onEndConfirm(val) {
            let date = new Date(this.startTimeStack)
            this.startTime = moment(date).format('YYYY-MM-DD')

            let endDate = new Date(val);
            this.endTime = moment(endDate).format('YYYY-MM-DD')
            this.endTimePopup = false
       }
    },


    mounted() {
        let maxMonth = new Date().getMonth() + 1;
        for (let i = 1; i <= maxMonth; i++) {
            this.monthList.push({
                text: `${i}月`,
                value: i
            })
        }
    }


}

</script>
<style lang="less" scoped>
.filter {
    background: #FFFFFF;
    border-radius: 8px;
    margin: 10px 18px;
    padding: 10px 17px;
    .top {
        margin-bottom: 7px;
    }

    .month {
        background: #EEEEEE;
        border-radius: 2px;
        padding: 4px 9px;
        margin-right: 10px;
    }
    .top, .bottom{
        display: flex;
        align-items: center;

        .right {
            display: flex;
            align-items: center;
            span {
                display: block; width: 9px;height: 9px; margin-right: 5px;}
            div, i {
                font-size: 12px;
                font-style: normal;
            }
            .key, i {
                color: #999;
                font-weight: 400;
            }

            .value {
                color: #666;
                font-weight: 500;
            }
        }
    }
     .handle {
        display: flex;
        align-items: center;

        i {
            width:20px;
            height: 1px;
            background-color: #212121;
            display: inline-block;
            margin: 0 5px;
        }
        div {
            font-size: 12px;
            &.placehoder {
                color: #999;
            }

            &.value {
                color: #212121;
            }
        }
        img {
            height: 5px;
            width: 8px;
            margin-left: 7px;
        }
    }
}


.list {
    .card {
        margin: 10px 18px;
        background: #FFFFFF;
        border-radius: 8px;
        padding-bottom: 13px;
        .top {
            padding: 10px 15px;
            .orderNum {
                display: flex;
                align-items: center;
                justify-content: space-between;

                div:first-child {
                    font-size: 12px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 1;
                }

                .state {
                    font-size: 12px;
                    font-weight: 400;
                    color: #DE4547;
                }
            }

            .from {
                font-size: 12px;
                color: #999999;
                line-height: 15px;
                margin-top: 5px;
            }
        }


        .content {
            display: flex;
            padding: 13px 15px;
            img {
                height: 80px;
                width: 95px;
                min-width: 95px;
                border-radius: 5px;
                margin-right: 18px;
            }

            .right {
                flex: 1;
                width: 0;
                .name {
                    font-size: 12px;
                    color: #999999;
                    line-height: 15px;
                }

                .price {
                    margin: 8px 0 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .money {
                        span {
                            line-height: 1;
                            color: #DE4547;
                            font-weight: bold;
                            &:first-child {
                                font-size: 12px;
                            }
                            &:last-child {
                                font-size: 15px;
                            }
                        }
                    }

                    .count {
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                    }
                }

                .shifu {
                    span {
                        color: #DE4547;
                        font-size: 12px;
                        font-weight: 500;
                    }

                    .money {
                        font-size: 12px;
                    }

                    .integral {
                        color: #CBAC6C;
                    }
                }
            }
        }

        .orderInfo {
            background: #F4F4F4;
            border-radius: 5px;
            padding: 11px 15px;
            margin: 0 13px;

            .item {
                display: flex;
                align-items: center;

                .key, .value {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #999999;
                }

                .value {
                    color: #212121;
                }
            }
        }
    }
}
</style>
